import React from "react";
import { listOfProjects } from "./listOfProjects";
import { Project } from "../types/DataTypes";

function Projects() {
  let projects: Project[] = [];
  for (let i = listOfProjects.length; i > 0; i--) {
    projects.push(listOfProjects[i - 1]);
  }

  return (
    <div className="p-2 md:mx-10 lg:mx-36">
      <h1 className="text-secondary text-5xl text-center">Projects</h1>
      <br />
      <div className="flex flex-wrap">

      {projects.map((project) => {
        let projectDetails = project.details
return(
              <div className="p-4 md:w-1/2 mx-auto lg:w-1/3 w-full shrink-0">
              <div className="shadow-none hover:shadow-xl h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden text-light2">
                  <img className="lg:h-72 md:h-64 sm:h-72 h-64 w-full object-cover hover:object-contain object-top" src={project.imgLink} alt={"Project " + project.name} />
                  <div className="p-6">
                      <h2 className="tracking-widest text-xs title-font font-medium text-light2 mb-1">{project.dates}</h2>
                      <h1 className="title-font text-lg font-medium text-lighter mb-3">{project.name}</h1>
                      <div className="leading-relaxed mb-3">
                        {projectDetails.map(line => {
                          return <p className="py-1">{line}</p>;
                        })}
                      </div>
                      <div className="flex items-center flex-wrap">
                          {project.projectLink ? <a href={project.projectLink} rel="noreferrer" target="_blank" className="text-accent inline-flex items-center md:mb-2 lg:mb-0">See Project
                          <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="#00fdd2" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                          </svg>
                          </a> : <></>}
                      </div>
                      <div className="flex items-center flex-wrap">
                          {project.gitLink ? <a href={project.gitLink} rel="noreferrer" target="_blank" className="text-accent inline-flex items-center md:mb-2 lg:mb-0">See Code
                          <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="#00fdd2" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                          </svg>
                          </a> : <></>}
                      </div>
                  </div>
              </div>
          </div>
      )})}
          </div>

    </div>
  );
}

export default Projects;
