import { WorkExperience } from "../types/DataTypes";

export const listOfWorkExperience: WorkExperience[] = [
	{
	  id: 0,
	  company: "Mosaic Sales Solutions",
	  position: "HP Innovation Events",
	  dates: "February 2020 - March 2020",
	  company_site: "https://www.mosaic.com/",
	  imgLink: "/img/mosaic.jpg",
	  skills: [
		"Sales",
		"Communication",
	  ],
	details: [
		"- Provided live demonstrations for customers who are looking to buy a printer to showcase unique capabilities and features of HP printers",
		"- Representing HP by talking to customers and explaining the benefits of going with HP's products to make a sale",
		"- Helping customers find the right product for them and making sure all their questions are answered",
		"- Assisting retail employees in Staples with sales, and providing any information needed as well as answering any questions they may have",
	],
	},
	{
	  id: 1,
	  company: "Mosaic Sales Solutions",
	  position: "Field Sales Representative",
	  dates: "November 2019 - Present",
	  company_site: "https://www.mosaic.com/",
	  imgLink: "/img/mosaic.jpg",
	  skills: [
		"Sales",
		"Communication",
	  ],
	details: [
		"- Representing HP by talking to customers and explaining the benefits of going with HP's products to make a sale",
		"- Helping customers find the right product for them and making sure all their questions are answered",
		"- Assisting retail employees in Staples and Best Buy with sales, and providing any information needed as well as answering any questions they may have",
	],
	},
	{
	  id: 2,
	  company: "One Iota Performance Inc.",
	  position: "Full Stack Developer Co-op",
	  dates: "September 2022 - April 2023",
	  company_site: "https://oneiotagolf.com/",
	  imgLink: "/img/oneIota-logo.jpg",
	  skills: [
		"Node.js",
		"Typescript",
		"Express",
		"MySQL",
		"Google Cloud Platform",
		"HTML/CSS"
	  ],
	details: [
		"- Created and updated EJS, JavaScript and CSS files to design robust and functional user-facing views to send to the client accessing the resource",
		"- Working with a multi-layered, object-oriented implementation for the company's backend AP",
		"- Helped transition a Firebase and Realtime database implementation to a MySQL implementation",
		"- Eliminated 100% of assigned bugs to ensure product quality is the highest it can possibly be",
		"- Practiced diverse concepts such as working with a multi-tiered architecture, microservices, cloud computing, development practices, and testing"
	],
	},
  ];
  