import React from "react";

export default function About() {
  return (
    <>
      <div className="">
        <div id="about" className="p-2 md:mx-10 lg:mx-36">
          <h1 className="text-secondary text-5xl text-center">About Me</h1>
          <br />
          <p className="text-light font-serif">
            <div className="sm:flex ">
              <div className="sm:w-2/3 sm:my-0 p-2">
                <img
                  src="/img/about2v2.jpg"
                  alt="Me in Toronto"
                  className="rounded-lg mx-auto my-2 sm:my-0 hover:shadow-lg"
                />
              </div>
              <div className="sm:w-1/3 p-2">
                <img
                  src="/img/about3v2.jpg"
                  alt="My Graduation"
                  className="rounded-lg mx-auto hover:shadow-lg"
                />
              </div>
            </div>
            <br />
            <p className="indent-7">
              Hello, and welcome to my website! My name is Shabbir Yusufali, and
              I'm thrilled to share with you some information about myself.
              Here, you'll find a comprehensive list of my most prominent
              projects, my work experience, my educational background, and my
              photography portfolio, which I post on my Instagram page{" "}
              <a
                className="underline"
                href="https://www.instagram.com/fift3photography/">
                @fift3photography
              </a>
              .
            </p>
            <br />
            <p className="indent-7">
              Currently, I'm a full-time student at Simon Fraser University,
              majoring in Computing Science as part of my Bachelor of Science
              degree. I'm based in the stunning province of British Columbia,
              Canada, which has been my home for the majority of my life. I have
              a deep appreciation for the outdoors, and I spend much of my free
              time hiking, camping, and taking pictures of the breathtaking
              scenery that BC has to offer. While I love exploring nature, I'm
              also passionate about many other hobbies, such as photography,
              reading, and playing video games. I have a curious mind and an
              insatiable thirst for knowledge, so I'm always on the lookout for
              new interests to explore.
            </p>
            <br />
            <p className="indent-7">
              One of my biggest dreams is to travel the world and experience all
              the different cultures and places that it has to offer. If you'd
              like to learn more about me or my interests, please don't hesitate
              to contact me using the button below.
            </p>
           
            <br />
            <br />
            <a
              href="https://www.linkedin.com/in/shabbir-yusufali"
              target="_blank"
              rel="noreferrer"
              className="rounded button hover:bg-dark hover:text-primary text-dark bg-primary px-4 py-2 font-mono">
              <i className="fa-brands fa-linkedin"></i> LinkedIn
            </a>
            <br />
            <br />
            <a
              href="mailto:shabyusufali@gmail.com"
              className="rounded button hover:bg-dark hover:text-primary text-dark bg-primary px-4 py-2 font-mono">
              <i className="fa-solid fa-envelope"></i> Email
            </a>
            <br />
            <br />
            <a
              href="https://www.instagram.com/shabz.y.portfolio/"
              className="rounded button hover:bg-dark hover:text-primary text-dark bg-primary px-4 py-2 font-mono">
              <i className="fa-brands fa-instagram"></i> Instagram
            </a>
            <br />
            <br />
          </p>
          <br />
          <br />
        </div>
      </div>
    </>
  );
}
